import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~7],
		"/design_system/button": [8],
		"/design_system/markdown": [9],
		"/examples": [10,[2]],
		"/examples/dashboard": [11,[2]],
		"/examples/datatable": [12,[2]],
		"/examples/icons": [13,[2]],
		"/examples/login": [~14,[2]],
		"/onboarding": [15],
		"/privacy_policy": [16],
		"/terms_of_service": [17],
		"/third_party_agreement": [18],
		"/waitlist": [19],
		"/workflows": [~20,[3]],
		"/workflows/[workflowid]": [21,[3,4]],
		"/workflows/[workflowid]/checks": [22,[3,4]],
		"/workflows/[workflowid]/checks/[checkid]": [23,[3,4]],
		"/workflows/[workflowid]/documents": [24,[3,4,5]],
		"/workflows/[workflowid]/pages": [25,[3,4]],
		"/workflows/[workflowid]/parties": [26,[3,4,6]],
		"/workflows/[workflowid]/parties/[entityid]": [27,[3,4,6]],
		"/workflows/[workflowid]/reports": [28,[3,4]],
		"/workflows/[workflowid]/settings": [29,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';